/** 
 * !!! DO NOT MODIFY !!!
 * 
 * File generated from types definition in project https://forge.cadoles.com/EFS/sheila.
 *
 * See misc/script/generate-type-defs.sh script for more informations.
 **/
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultCodeDataType = exports.getAnalysisTypesFromCatalogId = exports.AnalysisRequestState = void 0;
// Voir CCTP-IT09.pdf - II. STATUTS ET CYCLES DE VIE, A. STATUTS ET CYCLES DE VIE
var AnalysisRequestState;
(function (AnalysisRequestState) {
    // Stade 1 - La demande est prise en compte (reçu d’Inlog Labo server)
    AnalysisRequestState["TakenIntoAccount"] = "taken-into-account";
    // Stade 2 - Les examens de la demande sont intégrés dans des listes de travail qui sont exportés vers les automates
    AnalysisRequestState["IntegratedWorklist"] = "integrated-worklist";
    // Stade 3 - Des résultats sont importés dans le middleware pour chaque examen de cette
    // demande, à partir de HLA-FUSION et / ou Match-it. Ils sont soumis aux algorithmes
    // d'aide à l'interprétation paramétrés par labo
    AnalysisRequestState["ReceivedResults"] = "received-results";
    // Stade 4 - Les résultats de l'examens sont validés techniquement (VT), les résultats
    // peuvent être dévalidés par les profils utilisateurs définis
    AnalysisRequestState["TechValidatedResults"] = "tech-validated-results";
    // Stade 5 - Les résultats de l'examens sont validés avec composante experte (VE) par
    // les profils utilisateurs définis. Les résultats au stade VE ne peuvent être dévalidés et
    // modifiés que par un biologiste médical pour revenir à un stade VT.
    AnalysisRequestState["ExpertValidatedResults"] = "expert-validated-results";
    // Stade 6 - Les résultats sont transférés dans inlog laboserveur en format norme HPRIM
    // les résultats peuvent être dévalidés par un biologiste médical pour repasser au stade
    // pour être modifiable, puis passage en VE, puis réexportable après validation.
    AnalysisRequestState["TransferedResults"] = "transfered-results";
    // Stade 0 - Reprise des demandes données antérieures à la première nouvelle demande
    // validée dans l'outil – non exportable – non dévalidable
    //
    // Précision apportée par @srubier:
    // "C'est pour permettre d'interroger un dossier patient quand celui-ci n'a jamais reçu 
    // une demande analytique. Je précise. On prévoit envoyer un hprim pour un patient avec 
    // un code spécifique qui permet juste de déclencher le pipeline hprim import sans que 
    // ce soit un examen. Une sorte de première intégration pour consultation."
    AnalysisRequestState["Historized"] = "historized";
})(AnalysisRequestState || (exports.AnalysisRequestState = AnalysisRequestState = {}));
// Table associative de correspondance entre les types d'analyses et les préfixes d'identifiant
// de catalogues
// Voir https://nextcloud.cadoles.com/nextcloud/index.php/apps/files/?dir=/EFS-Shela/DOC09-SpecificationsFonctionnelsDetaillees&fileid=190281
var AnalysisRequestTypeCatalogPrefixTable = {
    "LSM12": ["DLUO", "DLUOA"],
    "LS1A04": ["SACL1", "SACL1T", "SACL1D"],
    "LS2A01": ["SACL2", "SACL2A", "SACL2T", "SACL2D"],
    "LSA104": ["SACL1A"],
};
// Retourne la liste des types d'analyse associés à un identifiant de catalogue, ou une tableau vide
// si aucun préfixe n'est associé à l'identifiant de catalogue
var getAnalysisTypesFromCatalogId = function (catalogId) {
    var prefixes = Object.keys(AnalysisRequestTypeCatalogPrefixTable);
    for (var i = 0; i < prefixes.length; i++) {
        var prefix = prefixes[i];
        if (!catalogId.startsWith(prefix))
            continue;
        return AnalysisRequestTypeCatalogPrefixTable[prefix];
    }
    return [];
};
exports.getAnalysisTypesFromCatalogId = getAnalysisTypesFromCatalogId;
// Type de donnée associé au code de résultat
// d'examen
// Voir https://nextcloud.cadoles.com/nextcloud/index.php/f/223720
var ResultCodeDataType;
(function (ResultCodeDataType) {
    // Groupe de résultats
    ResultCodeDataType["RESULT_GROUP"] = "result-group";
    // MFI CP
    ResultCodeDataType["MFI_CP"] = "mfi-cp";
    // MFI CN
    ResultCodeDataType["MFI_CN"] = "mfi-cn";
    // MFI max (hors CP)
    ResultCodeDataType["MFI_MAX"] = "mfi-max";
    // Synthése des spécificité positives
    ResultCodeDataType["SYNTH_POS"] = "synth-pos";
    // Commentaire libre
    ResultCodeDataType["COM"] = "com";
    // Test complémentaire
    ResultCodeDataType["COMP_TEST"] = "comp-test";
    // Catalogue
    ResultCodeDataType["CAT"] = "cat";
})(ResultCodeDataType || (exports.ResultCodeDataType = ResultCodeDataType = {}));
