import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { Config } from '../config'
import { selectSession } from '../selectors/session'
import { handleUnauthorizedResponseError } from './helper'
import { EVENT_REQUEST, EVENT_REQUEST_FAILURE, EVENT_REQUEST_SUCCESS, fetchEventsAction } from '../actions/events'
import { EventServerClient } from '../util/events'

export function* eventsSaga(): Generator {
  yield all([
    takeEvery(EVENT_REQUEST, fetchEventsRequest)
  ])
}

export function* fetchEventsRequest(action: fetchEventsAction): any {
  const session = yield select(selectSession)
  let result
  const client = new EventServerClient(Config.objectServerURL, session.idToken)
  try {
    result = yield call(client.fetchEvents, action.filter, action.options)
  } catch (error) {
    const handled: boolean = yield call(handleUnauthorizedResponseError, error, action)
    if (handled) return

    yield put({ type: EVENT_REQUEST_FAILURE, txId: (action as any).txId, error })
    return
  }
  yield put({ type: EVENT_REQUEST_SUCCESS, txId: (action as any).txId, events: result })
}
